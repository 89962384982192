import { useEffect, useState } from 'react';
import { getFromApiFunction } from '../../services_hooks/apiFunction';
import { Table } from 'antd';
import SelectComponent from '../SelectComponent';
import { getYearsFrom2020, yeartoSelectOption } from '../../core/helpers';


const DistributionTable = (parameters: any) => {


  const [value, setValue] = useState(0);
  const [data, setData] = useState<any | null>([]);
  const [columns, setColumns] = useState<any | null>([]);
  const [selectedYear, setSelectedYear] = useState<any>();

  const tables = [
    {
      tabTitle: "Distributions",
      endPoint: "/sessions/",
    },
  ];
  
  useEffect(() => {
    const table = tables[value];
    if (!table) return;
    setData([]);
    setColumns([]);

    getFromApiFunction(`${table.endPoint}verbose_names/`).then((response:any) => {
      const metadata = response.data;
      setColumns(
        metadata.map((column: any) => ({
          title: column.label,
          dataIndex: column.key,
          key: column.key,
          // width: "150px"
        })),
      );
    });
  }, [value, selectedYear]);

  useEffect(() => {
    const table = tables[value];
    let params = new URLSearchParams();
    if (selectedYear) {
      params.append('year', selectedYear);
    }
    if (!table) return;
    getFromApiFunction(`${table.endPoint}table/`, params).then((response) => {
      const responseData = response.data.results;
      setData(responseData);
    });
  }, [columns]);

  const changeYear = (value: any) => {
    setSelectedYear(value);
  }


  return (
    <>
      <div className='mb-4 w-1/2 mx-auto container'>
        <SelectComponent
          options={yeartoSelectOption(getYearsFrom2020())}
          onChange={changeYear}
          value={selectedYear}
          customPlaceHolder="Sélectionner une année"
          fitInParent={true}
          className="w-full"
        />
      </div>
      {
        columns && data && (
          <Table dataSource={data} columns={columns} />
        )
      }
    </>
  );
};

export default DistributionTable;
