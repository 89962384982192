export default class Credentials {
    refresh?: string;
    access?: string;
    user?: any;
    groups?: any;

  constructor(
    refresh: string,
    access?: string,
    user?: string,
    groups?: string,
  ) {
    this.access = access;
    this.refresh = refresh;
    this.user = user;
    this.groups = groups;
  }
}
