import { ActiveElement, Chart, ChartEvent } from "chart.js";
import { downloadDocument } from "../services_hooks/apiFunction";

const yeartoSelectOption = (values: number[]): any => {
  return values.map((value) => {
    return {
      value: value,
      label: value,
    };
  });
};

const getYearsFrom2020 = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];

  for (let year = 2020; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}

const transformToDataSet = (graphTitle: string, data: any, color?: any) => {
  let labels = data.map((element: any) => {
    return element.label;
  });
  let values = data.map((element: any) => {
    return element.value;
  });
  let planned = data.map((element: any) => {
    return element.planned;
  });

  return {
    labels: labels,
    datasets: [
      {
        label: graphTitle??"Effectuées",
        data: values,
        backgroundColor: color ?? "rgba(255, 99, 132, 0.5)",
      },
      {
        label: graphTitle??"Prévisions",
        data: planned,
        backgroundColor: color ?? "rgba(10, 243, 57, 0.99)",
      },
    ],
  };
};

const barChatOption: any = (graphTitle: string, onBarClick: any) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: graphTitle,
      },
    },
    onClick: onBarClick,
    scale: { ticks: { precision: 0 } },
  };
};

const getSelectionValues = (value: any, options: any[], isMulti?: boolean) => {
  let result = options.find((elmt: any) => elmt.value === value);
  if (isMulti) {
    result = options.filter((elmt: any) => value.includes(elmt.value));
  }
  return result;
};

function arraysEqual<T>(array1: T[], array2: T[]): boolean {
  // Check if the arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Sort the arrays and compare them
  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < array1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
}

const downloadFile = async (endpoint: string, docName: any) => {
  
  try {
    let filename = "liste-distribution.xlsx";
    const response = await downloadDocument(endpoint, docName);

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];

    if (contentDisposition) {
      const matches = contentDisposition.match(/filename="?(.*?)"?$/);
      if (matches?.[1]) {
        filename = matches[1];
      }
    }

    link.setAttribute("download", filename); // Set the file name
    document.body.appendChild(link);
    link.click();

    // Cleanup
    if (link.parentNode){
      link.parentNode.removeChild(link);
    }
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

function getFileExtension(filename: string): string | null {
  // Find the last occurrence of '.'
  const lastDotIndex = filename.lastIndexOf('.');

  // If there's no '.' or it's the last character, return null
  if (lastDotIndex === -1 || lastDotIndex === filename.length - 1) {
    return null;
  }

  // Return the substring after the last '.'
  return filename.slice(lastDotIndex + 1);
}

const getFileImageBasedOnExtension = (fileLink?: string) => {
  let availableImage = ['pdf', 'csv', 'txt', 'excel', 'word', 'xml'];
  if(fileLink){
    let fileExtension = getFileExtension(fileLink);
    if ( fileExtension && availableImage.includes(fileExtension)){
      return `${fileExtension}-doc.svg`;
    }
  }
  return "unknown-doc.svg";
}

const doesUserHaveRight = (userCredentials : any) => {
  let valToReturn = false;
  if (userCredentials && userCredentials.groups )
  {
    let tempTab = userCredentials.groups.filter((group: any) => group['name'].toLowerCase() == "superviseur");
    valToReturn = tempTab.length > 0;
  }

  return valToReturn;
}

export {
  yeartoSelectOption,
  transformToDataSet,
  barChatOption,
  getSelectionValues,
  arraysEqual,
  getYearsFrom2020,
  downloadFile,
  getFileImageBasedOnExtension,
  doesUserHaveRight
};
