import React, { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { MAP_VIEWS } from '../../core/constants';
import country from "../../core/departement.json";
import departNiger from "../../core/departementsNiger.json";
import regionNiger from "../../core/regionsNiger.json";
import data, { dataPoint } from "../../core/data";
import L from 'leaflet';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  FeatureGroup

} from "react-leaflet";
import { PopupComponent } from './MapMaker';
import { resizeWindow } from '../../core/constants';
import IndicateurSupperpositionButton from '../IndicateurSupperpositionButton';
import ModalSupperposition from '../ModalSupperposition';
import { Center } from '../../models/CenterModel';


delete (L.Icon.Default.prototype)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});



const MapBase = () => {

  const [isVisible, setIsVisible] = useState(false);
  const [viewIndex, setCurrentViewIndex] = useState(0);
  const bounds = L.geoJSON(departNiger).getBounds();
  const [view, setView] = useState(MAP_VIEWS[viewIndex]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[viewIndex].id);
  const [height, setHeight] = useState(window.innerHeight)
  const [isSupOpen, setIsSupOpen] = useState(true);
  const [dataCenters, setDataCenters] = useState([]);


  const handleOpenPopup = () => {
    setIsVisible(true);
  };

  const handleClosePopup = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    resizeWindow(setHeight);
    Center.fetchData().then((response) => {
      setDataCenters(Center.fromJsonList(response.data.results));
    });
  }, []);

  useEffect(() => {
    setView(MAP_VIEWS[viewIndex]);
    setTileKey(MAP_VIEWS[viewIndex].id);
  }, [viewIndex]);

  const changeMapSupperposition = (newIndex) => {
    setCurrentViewIndex(newIndex);
  };
  const closeSup = () => {
    setIsSupOpen(false);
  };
  const openSup = () => {
    setIsSupOpen(true);
  };

  useEffect(()=>{
    
  },[]);

  return (
    <>
      <div
        className={`overlay-inner absolute right-[40px] top-[150px] z-500 ${isSupOpen ? "hidden" : ""
          }`}
      >
        <IndicateurSupperpositionButton onClick={openSup} />
      </div>
      <ModalSupperposition
        isOpen={isSupOpen}
        onClose={closeSup}
        action={changeMapSupperposition}
        currentIndex={viewIndex}
      />
      <div className="w-screen relative" style={{ zIndex: 1, height: `${height}px` }}>
        <div
          className="grow h-max w-full "
          style={{
            display: "grid",
          }}
        >
          <MapContainer bounds={bounds} zoom={12} touchZoom={false} style={{ height: '100vh', width: '100%' }}>
            <FeatureGroup>
              {departNiger.features.map((feature, index) => {
                let dataJson = feature;

                return (
                  <FeatureGroup key={`${dataJson.properties.region}-${index}`}>
                    {
                      <GeoJSON
                        data={dataJson}
                        style={{ color: ["yellow", "green"][viewIndex] }}
                      />
                    }
                    <Popup>
                      <span>
                        {dataJson.properties.region}
                      </span>
                      <span>
                        {dataJson.properties.departement}
                      </span>
                    </Popup>
                  </FeatureGroup>
                );
              })}
            </FeatureGroup>
            {
              dataCenters.map((marker, index) => {

                return (
                  <Marker
                    position={[marker.latitude, marker.longitude]}
                    key={index}
                  >
                    <PopupComponent marker={marker} />
                  </Marker>
                );
              })
            }
            <TileLayer
              key={tileKey} {...view.tileLayerInfos}
            />
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default MapBase;