import { plainToClass } from "class-transformer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import Credentials from "../../models/CredentialsModel";

let userCredentials = null;

if (typeof window !== 'undefined') {
    const storedUser = localStorage.getItem("prsa-usercredentials");
    if (storedUser) 
    {
        userCredentials = plainToClass(Credentials, JSON.parse(storedUser));
    }
}

export interface AuthStateInterface {
    userCredentials?: Credentials | Credentials[] | null;
    isAuthenticated?: boolean;
}

export const initialState: AuthStateInterface = {
    userCredentials: userCredentials,
    isAuthenticated: userCredentials ? true : false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initializeAuth: (state, action: PayloadAction<any>) => {
            state = {
                ...state,
                userCredentials: action.payload,
                isAuthenticated: true,
            }
        },
        logUserIn: (state, action: PayloadAction<any>) => {
            state = {
                ...state,
                userCredentials: action.payload,
                isAuthenticated: true,
            }
            return state;
        },
        logUserOut: state => {
            state = {
                ...state,
                userCredentials: null,
                isAuthenticated: false,
            }
            return state;
        },
    }
})

export const { logUserIn, logUserOut } = authSlice.actions

export const selectUser = (state: RootState) => state.auth.userCredentials

export default authSlice.reducer