import React, { useEffect, useState } from "react";
import { UnorderedListOutlined, AppstoreOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { MapCard } from "../components/MapCard";
import { CustomMap } from "../models/MapModel";
import { Input } from 'antd';
import type { GetProps } from 'antd';

type SearchProps = GetProps<typeof Input.Search>;
const { Search } = Input;
export default function NumericLibrary ()
{
  const [maps, setMaps] = useState<any>([]);
  const [view, setView] = useState<any>("grid");
  const [docNameToSearch, setDocNameToSearch] = useState<any>();


  useEffect(() => {
    let params = new URLSearchParams();
    if (docNameToSearch) {
      params.append('name', docNameToSearch);
    }
    CustomMap.fetchData(params).then((response: any) => {
      setMaps(CustomMap.fromJsonList(response.data.results));
    });
  }, [docNameToSearch]);

  const changeView = () => {
    if(view === "grid"){
      setView("list");
    } else {
      setView("grid");
    }
  }
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setDocNameToSearch(value);
  };

  return (
    <div className="h-full w-full p-8 overflow-y-auto">
      <div className='mb-4 w-1/2 mx-auto container'>
        <Search
          placeholder="Rechercher par nom"
          allowClear
          onSearch={onSearch}
          onClear={() => setDocNameToSearch(null)}
        />
      </div>
      <div className="my-2">
        {
          maps && maps.length > 0 &&
          <div className="sm:grid md:grid-cols-6 sm:grid-cols-2 sm:gap-4">
              {maps.map((map: CustomMap) => (
                <div className="mb-4" key={map.pk}>
                  <MapCard
                    key={map.pk}
                    cardData={map}
                  />
                </div>
              ))}
          </div>
        }
      </div>
    </div>
  );
}