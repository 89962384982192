import { useState, useEffect, useMemo } from "react";
import { getYearsFrom2020, yeartoSelectOption } from "../../core/helpers"
import RegionSelector from "./homepage_components/RegionSelector";
import { ChartDataTypeInterface, TERRITORY_DIVISION_TYPES } from "../../core/constants";
import ChartDataTypeController from "./ChartDataTypeController";
import { Intrant } from "../../models/InputModel";
import { emitCustomEvent } from "react-custom-events";
import BarChart from "./homepage_components/BarChart";
import SelectComponent from "../../components/SelectComponent";

interface TdbComponentProps {
  elementToGet?: String
}

export default function TdbComponent(props: TdbComponentProps) {
  const {elementToGet} = props;
  const [intrantOptions, setIntrantOptions] = useState<any>([]);
  const [intrants, setIntrants] = useState<Intrant[]>([]);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [selectedIntrant, setSelectedIntrant] = useState<any>();
  const [baseEndpoint, setBaseEndpoint] = useState<String | undefined>();
  const [divisionName, setDivisionName] = useState<String | undefined>();

  const [selectedDivisionType, setSelectedDivisionType] = useState(
    TERRITORY_DIVISION_TYPES[0],
  );
  const [statsUnit, setStatsUnit] = useState(TERRITORY_DIVISION_TYPES[0].id);
  // const [statsUnit, setStatsUnit] = useState("departement");
  const [selectedDivTypeName, setDivisionTypeName] = useState(selectedDivisionType.id);

  const changeIntrant = (value: any) => {
    const intrantchoisi = intrants.filter((e: any) => e.id === value);
    setSelectedIntrant(intrantchoisi[0].id);
  };

  const changeYear = (value: any) => {
    setSelectedYear(value);
  }

  const filterByDivision = (divisionName: string) => {
    setDivisionName(divisionName);
    // const params:any = {};
    // params[selectedDivisionType.id] = divisionName;
    // setFilterParams({...params, ...filterParams});
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear);
    Intrant.fetchData().then((response: any) => {
      setIntrants(Intrant.fromJsonList(response.data.results));
    });
    if (elementToGet === 'intrants'){
      setBaseEndpoint("inputs/stats/");
    } else {
      setBaseEndpoint("distributions/stats/");
    }
  }, []);

  useEffect(() => {
    if (intrants) {
      let allIntrants: any[] = [];
      intrants.forEach(element => {
        allIntrants.push({ value: element.id, label: element.name });
      });
      setIntrantOptions([...allIntrants]);
    }
  }, [intrants]);

  const filterParams = useMemo(() => {
    const params: any = {};
    if (selectedYear) params["year"] = selectedYear;
    if (selectedIntrant) params["farm_input"] = selectedIntrant;
    if (divisionName && selectedDivisionType) {
      params[selectedDivisionType.id] = divisionName;
    }
    return params;
  }, [selectedYear, selectedIntrant, divisionName, selectedDivisionType]);


  const changeDivisionType = (newDivisionType: any) => {
    setStatsUnit(newDivisionType.id);
    setSelectedDivisionType(newDivisionType);
    setDivisionTypeName(newDivisionType.id);
  };


  return (

    <div className="h-full p-8 w-full">
      <div className="gap-2 mb-4 px-2">
        {/* <div className="w-full mb-2">
          <ChartDataTypeController
            selectedChartDataType={selectedDivisionType}
            onDataTypeChanged={changeDivisionType}
          />
        </div> */}
        {
          elementToGet === 'intrants' && <SelectComponent
            options={intrantOptions}
            onChange={changeIntrant}
            customPlaceHolder="Sélectionner un intrant"
            fitInParent={true}
            className="w-full"
          />
        }
      </div>
      <div className="h-full flex flex-row ">
        <div className="shadow-2xl drop-shadow-2xl rounded-2xl h-fit mah-h-fit m-4 ">
          <div
            className="p-0 relative"
            style={{ width: "500px", height: "500px", maxWidth: "500px" }}
          >
            {selectedDivTypeName && TERRITORY_DIVISION_TYPES.map((regionsData: any) => {
              if (selectedDivTypeName === regionsData.id) {
                return (
                  <RegionSelector
                    key={regionsData.id}
                    filterMethod={filterByDivision}
                    regions={regionsData.geojson}
                    interventionSet={regionsData.interventionSet}
                    statsUnitSetter={setStatsUnit}
                  />
                );
              }
              return null;
            })}
            <div className="w-1/2 absolute left-1 top-1 z-40">
              <SelectComponent
                options={yeartoSelectOption(getYearsFrom2020())}
                onChange={changeYear}
                value={selectedYear}
                customPlaceHolder="Sélectionner une année"
                fitInParent={true}
                className="w-full"
              />
            </div>
            {/* <div className="absolute top-1 z-40 w-full px-2">
              <div className="w-full flex justify-end">
                <Button
                  type="text" // Equivalent à IconButton sans arrière-plan
                  onClick={() => {
                    emitCustomEvent("re-init");
                    setFilterParams([]);
                  }}
                  icon={<TagOutlined style={{ fontSize: '20px' }} />} // Icône et style personnalisé
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="p-2 md:p-8 rounded-md mb-2 max-h-svh max-h-full overflow-y-auto w-full">
          { 
            baseEndpoint && selectedYear && intrants.map((intrant: Intrant) => {
              return <div className="w-2/3 mx-auto">
                <BarChart
                  graphName={""}
                  baseEndpoint={baseEndpoint}
                  statsUnitProps={statsUnit}
                  paramsProps={{ ...filterParams, ...{ farm_input: intrant.id } }}
                />
              </div>;
            })
          }
        </div>
      </div>
    </div>
  );
}
