import BaseModel from "./BaseModel";

export class CustomMap extends BaseModel {
  image: string;
  size: number;
  created: string;
  static url: string = "maps";

  constructor(id: number, name: string, image: string, size: number, created: string) {
    super(id, name);
    this.image = image;
    this.size = size;
    this.created = created;
  }

  static fromJson(json: any): CustomMap {
    return new this(json.id, json.name, json.image, json.size, json.created);
  }
}
