import React, { useEffect, useState } from 'react';
import { data, DataType } from '../data';
import { Button, Col, Descriptions, DescriptionsProps, Row, Space, Table, TableProps, Tag, message } from 'antd';
import AddDist from '../components/distributions/ModalAdd';
import axios from '../services_hooks/axios';
import { Center } from '../models/CenterModel';
import { Farmer } from '../models/FarmerModel';
import { Farm } from '../models/FarmModel';
import ScanQRCode from '../components/distributions/ScanQRCode';
import { error } from 'console';
import { resizeWindow } from '../core/constants';
import { useAppSelector } from '../services_hooks/auth_redux/auth_hooks';
import DistributionTable from '../components/distributions/DistributionTable';
import { DownloadOutlined } from '@ant-design/icons';
import FileLoader from '../components/FileLoader';
import { doesUserHaveRight, downloadFile, getYearsFrom2020, yeartoSelectOption } from '../core/helpers';
import { useSelector } from 'react-redux';


const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Centre',
    dataIndex: 'centre',
    key: 'centre',
  },
  {
    title: 'Ferme',
    dataIndex: 'ferme',
    key: 'ferme',
  },
  {
    title: 'Fermier',
    key: 'fermier',
    dataIndex: 'fermier',

  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <Space size="middle">
        <Tag color='green'>
          <a>Update</a>
        </Tag>
        <Tag color='processing'>
          <a>Delete</a>
        </Tag>
      </Space>
    ),
  },
];


const Distribution = () => {

  const userCredentials: any = useSelector((state: any) => state.auth.userCredentials);
  const isAuthenticated: any = useSelector((state: any) => state.auth.isAuthenticated);

  const [centers, setCenters] = useState<any>([]);
  const [farmers, setFarmers] = useState<any>([]);
  const [uuid, setUuid] = useState('');
  const [farmer, setFarmer] = useState<Farmer>()
  const [msgError, setMsgError] = useState("");
  const [isErrorUuid, setIsErrorUuid] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [downloading, setDownloading] = useState(false);
  const [hasRight, setHasRight] = useState(false);

  const reinitUUID = () => {
    setUuid("");
  }

  useEffect(() => {
    if (isAuthenticated) {
      let tempHasRight = doesUserHaveRight(userCredentials);
      setHasRight(tempHasRight);
    }
  }, [isAuthenticated])


  useEffect(() => {
    resizeWindow(setHeight);
  }, []);

  useEffect(() => {
    Center.fetchData().then((response: any) => {
      setCenters(Center.fromJsonList(response.data.results));
    });
    Farmer.fetchData().then((response: any) => {
      setFarmers(Farmer.fromJsonList(response.data.results));
    });
  }, []);

  //Récupération des informations du fermier
  useEffect(() => {
    Farmer.getAFarmer(uuid).then((response: any) => {
      setMsgError("");
      setIsErrorUuid(false);
      setFarmer(Farmer.fromJson2(response.data));
    }).catch((error: any) => {
      const msg = error.response.data.detail;
      if (error.response) {
        if (msg === "No Farmer matches the given query.")
          setMsgError("Aucun fermier ne coorespond au code scanner");
        else
          setMsgError("Erreur lors de la recherche du fermier");
        setIsErrorUuid(true);
      }
      else {
        // Handle error
      }
    })
  }, [uuid]);


  const benef: DescriptionsProps['items'] = [

    {
      key: '1',
      label: 'Nom',
      children: (<>{farmer?.name}</>)
    },
    {
      key: '2',
      label: 'Prenoms',
      children: (<>{farmer?.firstname}</>)
    },
    {
      key: '3',
      label: 'Sexe',
      children: (<>{farmer?.sex}</>)
    },
  ]

  useEffect(() => {
  }, [uuid])

  if (hasRight) {
    return (
      <div className='p-5'>
        <div className='mb-4 flex justify-end'>
          {
            downloading && (
              <FileLoader
                setDownloading={setDownloading}
                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiIconButton-sizeMedium css-1dhog8w-MuiButtonBase-root-MuiIconButton-root"
                eventToListen={`table-downloaded`}
              />
            )
          }
          {
            !downloading && <Button
              color="primary"
              onClick={() => {
                setDownloading(true);
                downloadFile('/sessions/table/', 'liste-distribution.xlsx');
              }}
            >
              <DownloadOutlined className="downloadIcon" color="primary" />
            </Button>
          }
        </div>
        
        <DistributionTable />
      </div>
    )
  }

  return (
    <div className='container flex justify-center items-center' style={{
      height: `${height}px`,
    }}>
      <div className='w-4/5 mt-5 text-center' style={{
        margin: 'auto'
      }}>
        {
          uuid && !isErrorUuid &&
          <>
            <Descriptions
              title="Bénéficiaire"
              items={benef}
            />
            <AddDist {...{ farmer, farmers, centers, rest: reinitUUID }} />
          </>
        }

        {
          (!uuid || isErrorUuid) &&
          <>
            <ScanQRCode uuidSetter={setUuid} />
            {
              isErrorUuid &&
              message.error(msgError)
            }
          </>
        }

      </div>
    </div>
  );
};

export default Distribution;