import React, { useEffect, useState } from "react";
import { Doc } from "../models/DocModel";
import { DocCard } from "../components/DocCard";
import { Input } from 'antd';
import type { GetProps } from 'antd';

type SearchProps = GetProps<typeof Input.Search>;
const { Search } = Input;

export default function NumericLibrary ()
{
  const [docs, setDocs] = useState<any>([]);
  const [view, setView] = useState<any>("grid");
  const [docNameToSearch, setDocNameToSearch] = useState<any>();


  useEffect(() => {
    let params = new URLSearchParams();
    if (docNameToSearch)
    {
      params.append('name', docNameToSearch);
    }
    Doc.fetchData(params).then((response: any) => {
      setDocs(Doc.fromJsonList(response.data.results));
    });
  }, [docNameToSearch]);

  const changeView = () => {
    if(view === "grid"){
      setView("list");
    } else {
      setView("grid");
    }
  }

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setDocNameToSearch(value);
  };


  return (
    <div className="h-full w-full p-8 overflow-y-auto">
      <div className='mb-4 w-1/2 mx-auto container'>
        <Search
          placeholder="Rechercher par nom"
          allowClear 
          onSearch={onSearch}
          onClear={ () => setDocNameToSearch(null)}
        />
      </div>
      <div className="my-2">
        {
          docs && docs.length > 0 &&
          <div className="sm:grid md:grid-cols-6 sm:grid-cols-2 sm:gap-4">
              {docs.map((doc: Doc) => (
                <div className="mb-4" key={`${doc.pk}-${doc.name}`}>
                  <DocCard
                    key={doc.pk}
                    cardData={doc}
                  />
                </div>
              ))}
          </div>
        }
      </div>
    </div>
  );
}