import { getFromApiFunction } from "../services_hooks/apiFunction";
import BaseModel from "./BaseModel";

export class Center extends BaseModel {
  email: string;
  phone_number: string;
  longitude: number;
  latitude: number;
  static url: string = "centers";

  constructor(id: number, name: string, email: string, phone_number: string, longitude: number, latitude: number) {
    super(id, name);
    this.email = email;
    this.phone_number = phone_number;
    this.longitude = longitude;
    this.latitude = latitude;
  }

  static fromJson(json: any): Center {
    return new this(json.id, json.name, json.email, json.phones, json.longitude, json.latitude);
  }

  static getCenter(id: any, params: URLSearchParams = new URLSearchParams()): any {
    return getFromApiFunction(`${this.url}/${id}/`, params);
  }
}
