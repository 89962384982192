import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Flex, Form, InputNumber, Modal, Select, Space, Spin, Upload, message, Typography, Checkbox, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Farmer } from '../../models/FarmerModel';
import { Intrant } from '../../models/InputModel';
import { useNavigate } from 'react-router-dom';
import { DistributionRequest } from '../../models/DistributionModel';

const { Text } = Typography;

interface AddDistPropsInterface {
  centers: any[];
  farmers: any[];
  farmer: any;
  rest: any;
}

const AddDist = (addDistPropsInterface: AddDistPropsInterface) => {
  const { farmers, centers, farmer, rest } = addDistPropsInterface;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCam, setIsModalOpenCam] = useState(false);
  const [form] = Form.useForm();
  const formDataDistri = new FormData();
  const dataDistri = new FormData();
  const [photoBenef, setPhotoBenef] = useState<string | null>(null);
  const [photoRecep, setPhotoRecep] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [currentCam, setCurrentCam] = useState<"benef" | "recep">("benef");
  const [isLoading, setIsLoading] = useState(false);
  const [isRepresentative, setIsRepresentative] = useState<any>(false);
  const [intrantOptions, setIntrantOptions] = useState<any>([]);
  const [farmersOptions, setFarmersOptions] = useState<any>([]);
  const [intrants, setIntrants] = useState<Intrant[]>([]);
  const location = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [Msg, setMsg] = useState<string[]>([]);

  useEffect(() => {
    // Fonction pour mettre à jour la largeur
    const handleResize = () => setWidth(window.innerWidth);

    // Ajouter l'écouteur d'événement pour détecter les changements de taille
    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    Intrant.fetchData().then((response: any) => {
      setIntrants(Intrant.fromJsonList(response.data.results));
    });
  }, []);

  useEffect(() => {

    if (farmers) {
      let allFarmers: any[] = [];
      farmers.map((element: any) => allFarmers.push({ value: element.id, label: element.name }))
      setFarmersOptions(allFarmers);
    }

    if (intrants) {
      let allIntrants: any[] = [];
      intrants.forEach(element => {
        allIntrants.push({ value: element.id, label: element.name });
      });
      setIntrantOptions([...allIntrants]);
    }
  }, [centers, farmers, intrants]);


  const benef = () => {
    setCurrentCam("benef");
    startCamera();
  }

  const recep = () => {
    setCurrentCam("recep");
    startCamera();
  }

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const showModal = () => {
    setIsModalOpen(true);
  }

  const handleOk = () => {
    setIsLoading(true);
    onFinish(form.getFieldsValue());

  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setMsg([]);
    setPhotoBenef("")
    setPhotoRecep("");
  };

  // Démarrer la caméra
  const startCamera = () => {
    setIsModalOpenCam(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        message.error("Erreur lors de l'accès à la caméra.");
      });
  };

  // Capturer la photo
  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        context.drawImage(videoRef.current, 0, 0, 300, 200);
        const imageData = canvasRef.current.toDataURL("image/png");
        currentCam === "benef" ?
          setPhotoBenef(imageData) :
          setPhotoRecep(imageData);
        stopCamera();
        setIsModalOpenCam(false);
        message.success("Photo capturée avec succès !");
      }
    }
  };

  // Arrêter la caméra
  const stopCamera = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
  };

  function base64ToBlob(base64: string) {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleCancel2 = () => {
  }

  // Soumettre le formulaire
  const onFinish = (values: any) => {
    const { is_representative, representative_firstname, representative_lastname } = values;
    let validate = true;
    const errors: string[] = [];
    if (is_representative && !representative_lastname){
      validate = false;
      errors.push("- Le nom du représentant du bénéficiaire n'est pas renseigné");
    }
    if (is_representative && !representative_firstname) {
      validate = false;
      errors.push("- Le(s) prénom(s) du représentant du bénéficiaire n'est(ne sont) pas renseigné(s)");
    }
    setMsg(errors);
    validate = validate && validateDistributions(values.distributions);

    if (validate) {
      formDataDistri.append("distributions", JSON.stringify({ "create": values.distributions }));
      formDataDistri.append("farmer", farmer.id);
      formDataDistri.append("recipient_id_image", new File([base64ToBlob(photoBenef ? photoBenef : "")], "photoBenef_image.png"));
      formDataDistri.append("recipient_image", new File([base64ToBlob(photoRecep ? photoRecep : "")], "photoRecep_image.png"));
      if(is_representative){
        formDataDistri.append("is_representative", is_representative);
        formDataDistri.append("representative_firstname", representative_firstname);
        formDataDistri.append("representative_lastname", representative_lastname);
      } else {
        formDataDistri.append("is_representative", "false");
      }

      DistributionRequest.setSession(formDataDistri).then((response: any) => {
        setIsLoading(false);
        const id = response.data['id'];
        dataDistri.append('distributions', JSON.stringify({ "create": values.distributions }));

        DistributionRequest.updateSession(id, dataDistri).then((response: any) => {
          setIsLoading(false);
          setIsModalOpen(false);
          message.success('Enregistrement réussi!');
          form.resetFields();
          setPhotoBenef("");
          setPhotoRecep("");
        }).catch((error: any) => {
          setIsLoading(false);
          setIsModalOpen(false);
          let errorToDisplay = "erreur d'enregistrement!";
          console.log(error);
          message.error(errorToDisplay);
        });
      }).catch((error: any) => {
        setIsLoading(false);
        setIsModalOpen(false);
        let errorToDisplay = "erreur d'enregistrement!";
        // if (error.response && error.response.data)
        message.error(errorToDisplay);
      });
    } else {
      setIsLoading(false);
    }

  };

  const validateDistributions = (distributions: any[]) => {
    const errors: string[] = [];
    const selectedInputs = new Set(); // Pour traquer les intrants déjà sélectionnés.
  
    if (!distributions || distributions.length === 0) {
      errors.push(`- Aucun distribution renseignée.`);
    } else {
      distributions.forEach((distribution, index) => {
        if (!distribution) {
          errors.push(`- Ligne ${index + 1}: Intrant non renseigné.`);
          return;
        }
  
        const { farm_input, quantity } = distribution;
  
        if (!farm_input) {
          errors.push(`- Ligne ${index + 1}: Intrant non renseigné.`);
          return;
        }
  
        if (selectedInputs.has(farm_input)) {
          errors.push(`- Ligne ${index + 1}: L'intrant a déjà été sélectionné.`);
          return;
        }
        selectedInputs.add(farm_input);
  
        const matchingEntry = farmer.remaining_distributions.find(
          (e: any) => e.input_id === farm_input
        );
  
        if (!matchingEntry) {
          errors.push(`- Ligne ${index + 1}: ${farmer.name} ${farmer.firstname} n'est pas habilité à recevoir cet intrant.`);
          return;
        }
  
        if (quantity === undefined || quantity === null) {
          errors.push(`- Ligne ${index + 1}: Quantité non renseignée.`);
        } else if (quantity <= 0) {
          errors.push(`- Ligne ${index + 1}: La quantité doit être supérieure à 0.`);
        } else if (quantity > matchingEntry.quantity) {
          errors.push(`- Ligne ${index + 1}: La quantité dépasse la limite autorisée (${matchingEntry.quantity}).`);
        }
      });
    }
  
    if (!photoBenef) errors.push(`- La photo du bénéficiaire n'est pas capturée.`);
    if (!photoRecep) errors.push(`- La photo de réception n'est pas capturée.`);
  
    setMsg(errors); 
    return errors.length === 0; 
  };

  
  return (
    <>
      <Space size={'large'} className='mt-3'>

        <Button key={"add"} type='primary' onClick={showModal}
          className='bg-primary hover:bg-secondary text-white mb-3'>
          Ajouter
        </Button>

        <Button key={"fin"} type='primary' onClick={rest}
          className='bg-primary hover:bg-secondary text-white mb-3' >
          Terminer
        </Button>
      </Space>

      <Modal
        key={"addDist"}
        title="Ajouter une distribution"
        open={isModalOpen}
        onClose={handleCancel2}
        closeIcon={false}
        footer={
          [
            <Button key={"close"} onClick={handleCancel}>
              Annuler
            </Button>,

            <Button key={"submi"} type="primary" htmlType="submit" onClick={handleOk} disabled={isLoading}
              className='bg-primary hover:bg-secondary text-white'
            >
              {isLoading ? <Spin /> : 'Soumettre'}
            </Button>
          ]
        }
      >
        <Form
          key={"forme1"}
          form={form}
          layout='vertical'
          labelCol={{ span: 30 }}
          wrapperCol={{ span: 30 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >

          <Form.List name="distributions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <div key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }}>

                      <Flex justify='space-between' align='center' wrap="wrap" style={{ width: '100%' }}>
                        <Form.Item
                          {...restField}
                          key={"farm_input"}
                          label="Intrant"
                          name={[name, 'farm_input']}
                          required
                          style={(width > 500)
                            ? { width: '48%' }
                            : { width: '95%', margin: '3px auto' }}

                        >
                          <Select
                            placeholder="Sélectionnez un intrant"
                            options={intrantOptions}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          key={"quantity"}
                          label="Quantité"
                          name={[name, 'quantity']}
                          //rules={[{ required: true, message: 'Please input!' }]}
                          style={(width > 500)
                            ? { width: '48%' }
                            : { width: '95%', margin: '3px auto' }}
                        >
                          <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>

                      </Flex>
                      <MinusCircleOutlined onClick={() => remove(name)} className='ml-1' />
                    </div>
                    <hr className='mb-3' />
                  </>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => {
                    add();
                  }} block icon={<PlusOutlined />}>
                    Ajouter un intrant
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Flex justify='space-between' align='center' wrap="wrap">
            <Form.Item
              key={"pb"}
              label="Photo d’identité du récepteur"
              name="recipient_id_image"
              valuePropName="recipient_id_image"
              required
              style={(width > 500)
                ? {}
                : { width: '100%', margin: '3px auto' }}
            >
              {!photoBenef && (
                <Button icon={<UploadOutlined />} onClick={benef} >
                  Capturer une photo
                </Button>
              )}

              {photoBenef && (
                <div>
                  <img
                    src={photoBenef}
                    alt="Photo id capturée"
                    style={{ marginTop: "10px", width: "200px", height: "100px" }}
                  />
                  <Button
                    type="dashed"
                    onClick={() => setPhotoBenef(null)}
                    style={{ marginTop: "10px" }}
                  >
                    Reprendre la photo
                  </Button>
                </div>
              )}
            </Form.Item>
            <Form.Item
              key={"pr"}
              label="Photo de la réception"
              name="recipient_image"
              valuePropName="recipient_image"
              required
              style={(width > 500)
                ? { width: '50%' }
                : { width: '100%', margin: '3px auto' }}
            >
              {!photoRecep && (
                <Button icon={<UploadOutlined />} onClick={recep}>
                  Capturer une photo
                </Button>
              )}
              {photoRecep && (
                <div>
                  <img
                    src={photoRecep}
                    alt="Photo reception capturée"
                    style={{ marginTop: "10px", width: "200px", height: "100px" }}
                  />
                  <Button
                    type="dashed"
                    onClick={() => setPhotoRecep(null)}
                    style={{ marginTop: "10px" }}
                  >
                    Reprendre la photo
                  </Button>
                </div>
              )}
            </Form.Item>
          </Flex>
          <hr />
          <br />
          
          <Form.Item
            key={"is_representative"}
            // label="Récupéré par un représentant ?"
            name={"is_representative"}
            valuePropName="checked"
          >
            <Checkbox onChange={(value) => setIsRepresentative(value.target.checked)}>
              Récupéré par un représentant ?
            </Checkbox>
          </Form.Item>
          {
            isRepresentative && (
              <Flex justify='space-between' align='center' wrap="wrap">
                <Form.Item
                  key={"representative_lastname"}
                  label="Nom du représentant du bénéficiaire"
                  name={"representative_lastname"}
                  rules={[{ required: isRepresentative, message: 'Veuillez renseigner le nom du représentant' }]}
                  style={(width > 500)
                    ? { width: '48%' }
                    : { width: '95%', margin: '3px auto' }}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  key={"representative_firstname"}
                  label="Prénoms du représentant du bénéficiaire"
                  name={"representative_firstname"}
                  rules={[{ required: isRepresentative, message: 'Veuillez renseigner les prénoms du représentant' }]}
                  style={(width > 500)
                    ? { width: '48%' }
                    : { width: '95%', margin: '3px auto' }}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </Flex>
            )
          }
          <hr />
          <br />
          <ul>
            {
              Msg && Msg.map((e: string) => {
                return (
                  <li key={e}>
                    <Text type='danger' className='text-left' >
                      {e}
                    </Text>
                  </li>
                );
              })
            }
          </ul>
          
        </Form>

        {/* Modal pour capturer la photo */}
        <Modal
          key={"photo"}
          title={currentCam === 'benef' ?
            "Photo du bénéficiaire" :
            "Photo de réception"}
          open={isModalOpenCam}
          onCancel={() => {
            stopCamera();
            setIsModalOpenCam(false);
          }}
          footer={[
            <Button key="cancel" onClick={() => setIsModalOpenCam(false)}>
              Annuler
            </Button>,
            <Button className='bg-primary' key="capture" type="primary" onClick={capturePhoto}>
              Capturer la photo
            </Button>,
          ]}
        >
          <video ref={videoRef} width="100%" height="auto" />
          <canvas
            ref={canvasRef}
            style={{ display: "none" }}
            width="300"
            height="200"
          />
        </Modal>
      </Modal>
    </>
  );
};

export default AddDist;