import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import { useEffect, useState } from "react";
import { getFileImageBasedOnExtension } from "../core/helpers";

export function DocCard (props: any) {

  const { cardData } = props;
  const [imageToDisplay, setImageToDisplay] = useState("unknown-doc.svg");

  useEffect(() => {
    setImageToDisplay(getFileImageBasedOnExtension(cardData.file))
  }, [cardData]);

  return (
    <a href={cardData.file} target="_blank" rel="noreferrer">
      <div className="max-w-sm mx-auto bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300 overflow-hidden max-h-80 border border-gray-200">
        <img className="w-full h-40 object-cover" src={imageToDisplay} alt="Card" />
        <div className="p-4 overflow-hidden">
          <p className="text-gray-700 text-sm line-clamp-1 capitalize">
            {cardData.name}
          </p>
          <p className="text-xs italic text-stone-400 capitalize">
            {cardData.created}
          </p>
        </div>
      </div>
    </a>
  );
}