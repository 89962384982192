import BaseModel from "./BaseModel";

export class Doc extends BaseModel {
  file: string;
  size: number;
  created: string;
  static url: string = "docs";

  constructor(id: number, name: string, file: string, size: number, created: string) {
    super(id, name);
    this.file = file;
    this.size = size;
    this.created = created;
  }

  static fromJson(json: any): Doc {
    return new this(json.id, json.name, json.file, json.size, json.created);
  }
}
