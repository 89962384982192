import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar/sidebar';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../services_hooks/auth_redux/auth_hooks';
import Credentials from '../models/CredentialsModel';


const Dashboard = () => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
  const [showOutlet, setShowOutlet] = useState(true);

  const handleOutlet = (val: boolean) => {
    setShowOutlet(val);
  }


  return isAuthenticated
    ? (
      <div className='flex'>
        <Sidebar handleOutlet={handleOutlet} />
        <div className='w-full h-screen'>
          <Outlet />
        </div>
      </div>
    )
    : <Navigate to="/login" />;
};

export default Dashboard;