import React, { useState } from "react";
import { useCustomEventListener } from "react-custom-events";

const FileLoader = (props:any) => {
  const { eventToListen, setDownloading, className } = props;
  // State to keep track of download progress
  const [progress, setProgress] = useState<any>(0);

  useCustomEventListener(eventToListen, (progress) => {
    setProgress(progress);
    if (progress === 100) {
      setDownloading(false);
    }
  });

  const radius = 10;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    <div className={className}>
      <svg width="24" height="24">
        <circle
          cx="12"
          cy="12"
          r={radius}
          fill="transparent"
          stroke="#e6e6e6"
          strokeWidth="2"
        />
        <circle
          cx="12"
          cy="12"
          r={radius}
          fill="transparent"
          stroke="#007bff"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          transform="rotate(-90 12 12)"
        />
      </svg>
    </div>
  );
};

export default FileLoader;
