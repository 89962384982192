import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Mapping from './pages/Mapping';
import Distribution from './pages/distribution';
import Dashboard from './pages/Dashboard';
import RouteErrorPage from './pages/RouteErrorPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './pages/Login';
import StoreProvider from './services_hooks/auth_redux/AuthStoreProvider';
import Deconnect from './pages/deconnect';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import HomePage from './pages/homepage/HomePage';
import NumericLibrary from './pages/NumericLibrary';
import MapLibrary from './pages/MapLibrary';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: "/mapping",
        element: <Mapping />,
      },
      {
        path: "/docs",
        element: <NumericLibrary />,
      },
      {
        path: "/maplist",
        element: <MapLibrary />,
      },
      {
        path: "/distribution",
        element: <Distribution />,
      },
      {
        path: "/",
        element: <Distribution />,
      },
      {
        path: "/dashboard",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/deconnect",
    element: <Deconnect />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StoreProvider >
      <App/>
      <RouterProvider router={router} />
      {/* <Distribution/> */}
    </StoreProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
